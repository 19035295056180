module.exports ={
  "propertyService": "https://vpcy4k5gyi.execute-api.us-east-1.amazonaws.com/demo",
  "domusoApiService": "https://2huj8xr5pe.execute-api.us-east-1.amazonaws.com/demo",
  "appService": "https://domusograilsweb-demo.devmuso.com",
  "reservationService": "https://6h8qh61n8d.execute-api.us-east-1.amazonaws.com/demo",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://s3wmy4kzw7.execute-api.us-east-1.amazonaws.com/demo",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-demo.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-demo.devmuso.com",
  "maintenanceUrl": "https://s3wmy4kzw7.execute-api.us-east-1.amazonaws.com/demo/maintenance",
  "env": "demo"
}